<template>
    <div>
        <vs-row>
            <vs-col vs-xs="12" vs-sm="10" vs-lg="11" class="px-0 pr-2 mb-base">
                <vs-input
                    class="w-full"
                    placeholder="Type to Search"
                    v-model="filters.search"
                />
            </vs-col>
            <vs-col vs-xs="12" vs-sm="2" vs-lg="1" class="flex mb-base p-0">
                <vx-tooltip text="Refresh Data" color="primary" class="ml-4">
                    <vs-button color="primary" type="filled" class="w-full" @click="init()" :disabled="loading">
                        <font-awesome-icon :icon="redoIcon" />
                    </vs-button>
                </vx-tooltip>
            </vs-col>
        </vs-row>
		<div class="vx-col w-full mb-base reports">
            <vx-card>
                <vs-table :data="results" slot="no-body" :hoverFlat="true" stripe>
                    <template slot="thead">
                        <vs-th v-for="i in generateEmptyCols(52)" :key="i" style="height: 0" class="p-0"></vs-th>
                    </template>
                    <vs-tr>
                        <vs-th style="max-width: 20px" class="border-bottom"></vs-th>
                        <vs-th style="min-width: 150px" class="border-bottom">Entity</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Apr</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">May</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Jun</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Jul</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Aug</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Sep</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Oct</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Nov</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Dec</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Jan</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Feb</vs-th>
                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Mar</vs-th>
                    </vs-tr>
                    <vs-tr>
                        <vs-th></vs-th>
                        <vs-th></vs-th>
                        <template v-for="i in 12">
                            <vs-th :key="`f-${i}`" class="text-center border-bottom border-left">F</vs-th>
                            <vs-th :key="`nf-${i}`" class="text-center border-bottom">NF</vs-th>
                            <vs-th :key="`of-${i}`" class="text-center border-bottom">O</vs-th>
                            <vs-th :key="`naf-${i}`" class="text-center border-bottom border-right">N/A</vs-th>
                        </template>
                    </vs-tr>
                    <template>
                        <template v-for="(item, index) in results">
                            <vs-tr :key="item.id" :data="generateEmptyCols(52)">
                                <!-- level 2 -->
                                <template class="expand-user" slot="expand" v-if="item.filing_history && item.filing_history.length">
                                    <vs-table :data="item.filing_history" class="w-full">
                                        <template slot="thead">
                                            <vs-th v-for="i in generateEmptyCols(52)" :key="i" style="height: 0" class="p-0"></vs-th>
                                        </template>
                                        <vs-tr>
                                            <vs-th rowspan="2" :data="generateEmptyCols(52)"></vs-th>
                                            <vs-th rowspan="2" style="min-width: 120px">Compliance</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Apr</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">May</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Jun</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Jul</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Aug</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Sep</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Oct</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Nov</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Dec</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Jan</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Feb</vs-th>
                                            <vs-th colspan="4" class="text-center border-left border-right border-bottom">Mar</vs-th>
                                        </vs-tr>
                                        <vs-tr>
                                            <template v-for="i in 12">
                                                <vs-th :key="`f-${i}`" class="text-center border-bottom border-left">F</vs-th>
                                                <vs-th :key="`nf-${i}`" class="text-center border-bottom">NF</vs-th>
                                                <vs-th :key="`of-${i}`" class="text-center border-bottom">O</vs-th>
                                                <vs-th :key="`naf-${i}`" class="text-center border-bottom border-right">N/A</vs-th>
                                            </template>
                                        </vs-tr>
                                        <vs-tr v-for="(com, comIndex) in item.filing_history" :key="com.id">
                                            <!-- Level 3-->
                                            <template class="expand-user" slot="expand" v-if="com.entity_compliances && com.entity_compliances.length">
                                                <vs-table :data="com.entity_compliances" class="w-full">
                                                    <vs-tr>
                                                        <vs-th rowspan="2" style="min-width: 120px">Registration</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Apr</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">May</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Jun</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Jul</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Aug</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Sep</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Oct</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Nov</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Dec</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Jan</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Feb</vs-th>
                                                        <vs-th colspan="4" class="text-center border-left border-right border-bottom">Mar</vs-th>
                                                    </vs-tr>
                                                    <vs-tr>
                                                        <template v-for="i in 12">
                                                            <vs-th :key="`f-${i}`" class="text-center border-bottom border-left">F</vs-th>
                                                            <vs-th :key="`nf-${i}`" class="text-center border-bottom">NF</vs-th>
                                                            <vs-th :key="`of-${i}`" class="text-center border-bottom">O</vs-th>
                                                            <vs-th :key="`naf-${i}`" class="text-center border-bottom border-right">N/A</vs-th>
                                                        </template>
                                                    </vs-tr>
                                                    <vs-tr v-for="(reg, regIndex) in com.entity_compliances" :key="reg.id">
                                                        <vs-td>{{ reg.registration.registration_identifier }}</vs-td>
                                                        <template v-for="i in fy">
                                                            <vs-td :key="`f-${i}`" :class="{'text-success font-bold': reg.count[i].filed > 0 }" class="border-left text-center">{{ reg.count[i].filed }}</vs-td>
                                                            <vs-td :key="`nf-${i}`" :class="{'text-warning font-bold': reg.count[i].not_filed > 0 }" class="text-center">{{ reg.count[i].not_filed }}</vs-td>
                                                            <vs-td :key="`of-${i}`" :class="{'bg-danger text-white': reg.count[i].overdue > 0 }" class="text-center">{{ reg.count[i].overdue }}</vs-td>
                                                            <vs-td :key="`naf-${i}`" class="border-right text-center">{{ reg.count[i].not_applicable }}</vs-td>
                                                        </template>
                                                    </vs-tr>
                                                </vs-table>
                                            </template>
                                            <vs-td v-else></vs-td>
                                            <!-- Level 3 ends-->

                                            <vs-td>{{ com.name }}</vs-td>
                                            <template v-for="i in fy">
                                                <vs-td :key="`f-${i}`" :class="{'text-success font-bold': com.count[i].filed > 0 }" class="border-left text-center">{{ com.count[i].filed }}</vs-td>
                                                <vs-td :key="`nf-${i}`" :class="{'text-warning font-bold': com.count[i].not_filed > 0 }" class="text-center">{{ com.count[i].not_filed }}</vs-td>
                                                <vs-td :key="`of-${i}`" :class="{'bg-danger text-white': com.count[i].overdue > 0 }" class="text-center">{{ com.count[i].overdue }}</vs-td>
                                                <vs-td :key="`naf-${i}`" class="border-right text-center">{{ com.count[i].not_applicable }}</vs-td>
                                            </template>
                                        </vs-tr>
                                    </vs-table>
                                </template>
                                <vs-td v-else></vs-td>
                                <!-- level 2 end -->
                                <vs-td>
                                    {{ item.name }}
                                    <small class="block">
                                        <span class="text-primary">ID:</span> {{ item.id }}
                                    </small>
                                </vs-td>
                                <template v-for="i in fy">
                                    <vs-td :key="`f-${i}`" :class="{'text-success font-bold': getValue('e', item, i, 'filed') > 0 }" class="border-left text-center">{{ getValue('e', item, i, 'filed') }}</vs-td>
                                    <vs-td :key="`nf-${i}`" :class="{'text-warning font-bold': getValue('e', item, i, 'not_filed') > 0 }" class="text-center">{{ getValue('e', item, i, 'not_filed') }}</vs-td>
                                    <vs-td :key="`of-${i}`" :class="{'bg-danger text-white': getValue('e', item, i, 'overdue') > 0 }" class="text-center">{{ getValue('e', item, i, 'overdue') }}</vs-td>
                                    <vs-td :key="`naf-${i}`" class="border-right text-center">{{ getValue('e', item, i, 'not_applicable') }}</vs-td>
                                </template>
                            </vs-tr>
                        </template>
                    </template>
                </vs-table>
            </vx-card>
        </div>
        <template v-if="!disable_pagination">
            <vs-row v-if="total_results" class="mt-3 text-center">
                <vs-pagination :total="total_pages" v-model="current_page" goto class="mx-auto"></vs-pagination>
            </vs-row>
        </template>
    </div>
</template>


<script>
import { faPlus, faRedo } from '@fortawesome/free-solid-svg-icons';
import {Request} from "@/utils/requests"

export default {
	props: {
        embedded_mode: {
            required: false,
            default: false
        },
        embedded_filters: {
            required: false,
            default: () => {}
        },
        disable_pagination: {
            required: false,
            default: false
        },
        global_mode: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        let offset = parseInt(this.$route.query.offset || 0);
        let limit = parseInt(this.$route.query.limit || 20);

        let current_page = Math.ceil((offset + limit) / limit);
        return  {
            results: [],

			next_url: null,
            loading: false,
            total_results: null,
            current_page: current_page,
            filters: {
                search: this.$route.query.search || null,
                ordering: this.$route.query.ordering || [],
                offset: offset,
                limit: limit
            },

            submit_disabled: false,
            api_errors: {},
            role_options: [
                {label: 'Admin', value: 'a'},
                {label: 'Staff', value: 's'},
                {label: 'Client', value: 'c'},
            ],

            organisation_user_action: {
                index: null,
                action: null
            },
            fy: [
                4,5,6,7,8,9,10,11,12,1,2,3
            ]
        }
    },
    mounted() {
        this.init()
    },
	computed: {
        total_pages() {
            if(this.total_results == 0 || this.filters.limit == 0)  return 1
            return Math.ceil(this.total_results/this.filters.limit)
        },
        redoIcon: () => faRedo,
        addIcon: () => faPlus
    },
    watch: {
        filters: {
            deep: true,
            handler(newV) {
                if (this.current_page == null) return;

                if (this.timer) clearTimeout(this.timer);

                this.timer = setTimeout(() => {
                    this.$router.push({ name: this.$route.name, query: {...newV, ...this.embedded_query_params} });
                }, 800);
            }
        },
        "$route.query"(newV) {
            this.load_data(newV);
        },
        current_page(newV, oldV) {
            if (newV == oldV) return;
            this.filters.offset = (newV - 1) * this.filters.limit;
        }
    },
    methods: {
        init(){
            this.load_data(this.filters)
        },
        calculate_page() {
            if (this.filters.offset == 0) this.current_page = 1;
            else
                this.current_page = Math.ceil(this.filters.offset / this.filters.limit);
        },
        load_data(filters=null) {
            this.loading = true;
            this.$vs.loading();

            let url = this.$store.state.apiendpoints.reports_annual_filing_history

            let payload = filters

            if (!payload) payload = {};

            if (this.embedded_mode && this.embedded_filters) {
                payload = { ...payload, ...this.embedded_filters };
            }

            if (this.global_mode) {
                payload['global'] = true
            }

            this.api_errors = {}

            Request(this, 'GET', url, payload).then(
                (res) => {
                    this.results = res.results
                    this.total_results = res.count
                    this.next_url = res.next;
                    this.loading = false;
                    this.$vs.loading.close();
                },
                (err) => {
                    this.loading = false;
                    this.$vs.loading.close();
                    if (err.error) {
                        this.api_errors = err.error

                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "Please fix the errors and try again!",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "Unable to load Organisations Users. Please try again later.",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }
            )
        },
        do_capitalize(value) {
            if (!value) return ''
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        _do_perform_action(index, action_type) {
            this.organisation_user_action.index = index
            this.organisation_user_action.action = action_type
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Are you sure?',
                text: `Are you sure you want to ${this.do_capitalize(action_type)} this user?`,
                accept: () => this.action(),
                'accept-text': 'Yes',
                'cancel-text': 'No'
            })
        },
        action() {
            this.loading = true;
            this.$vs.loading();

            let payload = {
                organisation_user: this.results[this.organisation_user_action.index].id,
                action: this.organisation_user_action.action
            }

            let url = this.$store.state.apiendpoints.organisation_user_action

            Request(this, 'POST', url, null, payload).then(
                (res) => {
                    this.loading = false;
                    this.$vs.loading.close();
                    this.$set(this.results[this.organisation_user_action.index], 'is_active', this.organisation_user_action.action == 'suspend' ? false : true)

                    this.$vs.notify({
                        time: 3000,
                        title: 'Success',
                        text: "Your action is performed successfully.",
                        iconPack: 'feather',
                        icon: 'icon-check-circle',
                        color: 'success'
                    })
                },
                (err) => {
                    this.loading = false;
                    this.$vs.loading.close();
                    if (err.error) {
                        this.api_errors = err.error

                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: (err.error && err.error.__non_field_errors__) ? err.error.__non_field_errors__.join(',') : 'An Error Occured. Please Try Again.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "Please try again later.",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }
            )
        },
        getValue(cc, item, month, key) {
            // if (!item.filing_history) return 0
            if (!this._value_cache) {
                this._value_cache = {}
            }

            let cache_key = `${cc}_${item.id}_${month}_${key}`

            if (this._value_cache[cache_key] != undefined) {
                return this._value_cache[cache_key]
            }

            let v = item.filing_history.reduce(
                (acc, v) => acc + v.count[month][key],
                0
            )

            this._value_cache[cache_key] = v
            return v
        },
        generateEmptyCols(n) {
            let d = []
            for(let i=0; i<n; i++) d.push(i)
            return d
        }
    }
}
</script>

<style lang="scss">
    .reports {
        .border-left {
            border-left: 1px solid rgba(0,0,0,0.1);
        }
        .border-right {
            border-right: 1px solid rgba(0,0,0,0.1);
        }
        .border-bottom {
            border-bottom: 1px solid rgba(0,0,0,0.1);
        }
        .text-center {
            .vs-table-text {
                justify-content: center;
            }
        }
    }
</style>